<template>
  <div class="page">
    <navTop title="武夷山市招商地图" :leftIcon="true"></navTop>

    <div id="chart-box">
      <div id="wy-map" ref="mapChart">

      </div>
      <van-button class="to-pri-map" type="default" size="small" round to="/privateMap">管理端</van-button>
      <van-image class="plus_btn" :src="require('../assets/imgs/plus.jpeg')" @click="enlarge"></van-image>
      <van-image class="sub_btn" :src="require('../assets/imgs/sub.jpeg')" @click="ensmall"></van-image>
    </div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import WYS from '@/assets/js/WYS.json'
import navTop from "@/components/common/navTop.vue";
import { projectPageList } from "@/api/index";
import { Toast } from 'vant';


export default {
name: "Wymap",
components: {
  navTop,
},
data() {
  return {
    // 地图配置
    mapOption: {
      tooltip: {
        show: true
      },
      legend: {
        show:true,
        orient: 'vertical',
        left: 10,
        top: 35,
        textStyle: {
          // color: '#fff',
          fontSize: 16,
          fontWeight: 'bold'
        },
        selectedMode: 'multiple',
        icon: 'circle',
        itemWidth: 10,
        itemHeight: 10,
        itemGap: 20,
        inactiveColor: "#666",
        selected: {
          "在谈项目": true,
          '已签约项目': true,
          '落地后停滞项目': false,
          '已落地项目': false,
        }
      },
      geo: {
        map: 'WYS',
        roam: true,
        scaleLimit: { //滚轮缩放的极限控制
          min: 0.8,
          max: 10
        },
        zoom: 1.2,
        label: {
          show: true,
          color: 'black',
          // fontWeight: 'bold',
          fontSize: 14
        },
        itemStyle: {
          normal: {
            borderColor: 'black',
            borderWidth: 1,
          },
        },
        emphasis: {
          disable: true
        },
        silent: true,
        regions: [
          {
            name: "洋庄乡",
             itemStyle: {
              normal: {
                areaColor: '#5da689',
              },
             }
          },
          {name: "崇安街道", itemStyle: {
              normal: {
                areaColor: '#f0cac5',
              },
            }},
          {name: "武夷街道", itemStyle: {
              normal: {
                areaColor: '#f2ecd5',
              },
            }},
          {name: "星村镇", itemStyle: {
              normal: {
                areaColor: '#66bfbc',
              },
            }},
          {name: "上梅乡", itemStyle: {
              normal: {
                areaColor: '#66bfbc',
              },
            }},
          {name: "兴田镇", itemStyle: {
              normal: {
                areaColor: '#f0cac5',
              },
            }},
          {name: "五夫镇", itemStyle: {
              normal: {
                areaColor: '#5da689',
              },
            }},
          {name: "吴屯乡", itemStyle: {
              normal: {
                areaColor: '#f2ecd5',
              },
            }},
          {name: "新丰街道", itemStyle: {
              normal: {
                areaColor: '#5da689',
              },
            }},
          {name: "岚谷乡", itemStyle: {
              normal: {
                areaColor: '#f0cac5',
              },
            }},
        ],
      },
      series: [
        {
          name: "在谈项目",
          itemStyle: {color: "blue"},
          status: 1
        },
        {
          name: "已签约项目",
          itemStyle: {color: "yellow"},
          status: 2
        },
        {
          name: "落地后停滞项目",
          itemStyle: {color: "orange"},
          status: 3
        },
        {
          name: "已落地项目",
          itemStyle: {color: "green"},
          status: 4
        },
      ]
    },
    commonEffectConfig: {
      type: 'effectScatter',
      coordinateSystem: 'geo',
      showEffectOn: "render",
      symbolSize: 10,
      geoIndex: 0,
      tooltip: {
        show: true,
        trigger: 'item',
        className: 'site-tooltip-box',
        backgroundColor: '#fafafa',
        triggerOn: "click",
        padding: 10,
        enterable: true,
        position: function (point, params, dom, rect, size) {
          let x = size.viewSize[0] - size.contentSize[0]
          return [x / 2, '20%']  //返回x、y（横向、纵向）两个点的位置
        },
        textStyle: {
          fontSize: 16
        },
        extraCssText: 'white-space: inherit;overflow-y: auto;',
        formatter: (params) => {
          let content = `
            <div style="width:300px;min-height:280px;max-height:300px;overflow-y:auto'">
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/dc06ee8d35ebbc7008ec922263625bdb.png" alt=""/>
                  项目名称：</span>
                <span style="flex: 2">${params.data.name}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/02594230de781314528ce3708df642cb.png" alt=""/>
                  总投资：</span>
                <span style="flex: 2">${params.data.input_money}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/c26b5683e03b29ce21c7fca3afcb7a5f.png" alt=""/>
                  投资者：</span>
                <span style="flex: 2">${params.data.investor}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/06a8f9dd043460d347db2e4f8a245d13.png" alt=""/>
                  属地乡镇：</span>
                <span style="flex: 2">${params.data.unit}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/f71fff149e47c4010f6dfb9e851b899d.png" alt=""/>
                  项目状态：</span>
                <span style="flex: 2">${params.data.status_text}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/9a579d415a041eefff06011aa3734f24.png" alt=""/>
                  项目备注：</span>
                <span style="flex: 2">${params.data.remark}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/4756702b3d0525dca9bcc09f7a3d2529.png" alt=""/>
                  建设地址：</span>
                <span style="flex: 2">${params.data.address}</span>
              </div>
              <div style="display:flex;margin-bottom: 8px;">
                <span style="flex: 1.2;color: #5396FF;">
                  <img style="width: 15px;height:15px;transform: translateY(2px);" src="${this.baseUrl}/uploads/20230322/ca9ea6b6a56391e459c2b23f616375c7.png" alt=""/>
                  建设内容：</span>
              </div>
              <span style="flex: 2" v-html ref="contentRef">${params.data.content}</span>
            </div>
              `
          return content
        },
      },
      rippleEffect: {
        brushType: 'stroke',
        scale: 3
      },
      hoverAnimation: true,
      encode: {
        value: 2
      },
      itemStyle: {
        shadowBlur: 10,
        shadowColor: '#3CDED5',
      },
      zlevel: 1,
      data: []
    },
    mapPointData: [
      // {
      //   name: "武夷山华祥苑茶博城",
      //   value: [118.15790595813104, 27.96623287832463],
      //   status: 1,
      //   money: 1200000000,
      //   address: '武夷山市茶叶总厂旁',
      //   remark: '开工'
      // },
    ],
    mapChart: null,
    baseUrl: process.env.VUE_APP_API
  }
},
mounted() {
  this.$nextTick(() => {
    this.proPageList()
  })
},
updated(){
    // const contentImages = this.$refs.contentRef.querySelectorAll("img");
    //   contentImages.forEach((img) => {
    //     img.style.maxWidth = "100%";
    //   });
},
methods: {
  // 初始化地图
  initMap() {
    this.mapChart = echarts.init(this.$refs.mapChart);
    echarts.registerMap('WYS', WYS)
    // 数据根据status分成多个数组
    const result = this.mapPointData.reduce((acc, curr) => {
      if (!acc[curr.status]) {
        acc[curr.status] = [curr];
      } else {
        acc[curr.status].push(curr);
      }
      return acc;
    }, {});

    this.mapOption.series.forEach((item, index) => {

      this.mapOption.series[index] = {...item, ...this.commonEffectConfig}
      this.mapOption.series[index].itemStyle = item.itemStyle
      this.mapOption.series[index].data = result[item.status]

    })


    this.mapChart.setOption(this.mapOption)
  },

  // 项目列表
  async proPageList() {
    try {
      const { code, data } = await projectPageList({page:1, page_size: 100});
      this.mapPointData = data.data.map(item => {
        item.value = [item.longitude, item.latitude]
        item.content = item.content.replace(/\<img/gi, '<img style="max-width:100%;"');
        return item;
      })
      this.initMap()
    } catch (e) {
      Toast.fail(e)
      console.log(e);
    }
  },

  // 地图放大
  enlarge(){
    var mapZoom = this.mapChart.getOption().geo[0].zoom;
    if(mapZoom < 10) {
      mapZoom = mapZoom*1.5
    }
    this.mapChart.setOption({
      geo: {
        zoom: mapZoom
      }
    });
  },
  // 地图缩小
  ensmall(){
    var mapZoom = this.mapChart.getOption().geo[0].zoom;
    if(mapZoom > 0.8) {
      mapZoom = mapZoom/1.5
    }
    this.mapChart.setOption({
      geo: {
        zoom: mapZoom
      }
    });
  },
},

}
</script>

<style scoped>
#chart-box {
width: 100vw;
height: 100vh;
/* background: #fafafa; */
}

#wy-map {
width: 100%;
height: 100%;
}
.to-pri-map{
  position: absolute;
  right: 20px;
  top: 80px;
  border-radius: 10px;
  border: 1px solid;
}
.plus_btn{
  position: fixed;
  right: 80px;
  bottom: 50px;
  z-index: 99999999;
  width: 40px;
  height: 40px;
}
.sub_btn{
  position: fixed;
  right: 40px;
  bottom: 50px;
  z-index: 99999999;
  width: 40px;
  height: 40px;
}
</style>